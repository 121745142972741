/**
 * Handles the sub menu toggling on the navbar
 * 
 * @returns void
 */
const NavbarSubMenu = () => {
  if ( window.screen.width > 1025 ) {
    handleDeskTopSubMenu();
  } else {
    handleMobileSubMenu();
  }
}

const handleDeskTopSubMenu = () => {
  const subMenuItems = document.querySelectorAll('.bg_link') as NodeListOf<HTMLElement>;
  const subMenu = document.querySelectorAll('.sub-menu') as NodeListOf<HTMLElement>;

  subMenuItems.forEach((item) => {
    item.addEventListener('mouseover', (e) => {
      const target = e.target as HTMLElement;      
      const parent = target.parentElement as HTMLElement;
      const subMenu = parent.querySelector('.sub-menu') as HTMLElement;

      if (subMenu) {
        parent.classList.add('bg_active');
        subMenu.classList.add('active');
      }
    }, false);

    item.addEventListener('mouseout', (e) => {
      const target = e.target as HTMLElement;
      const parent = target.parentElement as HTMLElement;
      const subMenu = parent.querySelector('.sub-menu') as HTMLElement;

      if (!parent.contains(e.relatedTarget as Node) && subMenu) {
        parent.classList.remove('bg_active');
        subMenu.classList.remove('active');
      } 
    }, false);
  });

  subMenu.forEach((item) => {
    item.addEventListener('mouseleave', () => {   
      item.parentElement!.classList.remove('bg_active');   
      item.classList.remove('active');
    }, false);
  });
}

const handleMobileSubMenu = () => {
  const subMenuItems = document.querySelectorAll('.fa') as NodeListOf<HTMLElement>;
  const back = document.querySelectorAll('.bg_close-submenu') as NodeListOf<HTMLElement>;

  subMenuItems.forEach((item) => {
    //console.log('item', item);
    item.addEventListener('click', (e) => {
      const target = e.target as HTMLElement;
      //console.log('target', target );
      const parent = target.parentElement as HTMLElement;
      //console.log('parent', parent);
      const subMenu = parent.querySelector('.sub-menu') as HTMLElement;   

      if (subMenu) {
        subMenu.classList.add('active');
      }
    }), false;
  });

  back.forEach((item) => {
    //console.log('back', item);
    item.addEventListener('click', () => {
      const parent = item.parentElement as HTMLElement;
      //console.log(parent)
      parent.classList.remove('active');
    });
  });
}

export default NavbarSubMenu;