/**
 * This file is used to create a print version of the recipe page.
 */

/**
 * Creates an HTML element
 * 
 * @param {string} type - The type of element to create
 * @param {any} props - The properties to add to the element
 * 
 * @returns HTMLElement
 */
const createElement = (type: string, props: any = {}) => {
  const element = document.createElement(type);
  Object.keys(props).forEach((prop: any) => {
    if (prop === 'style') {
      Object.keys(props[prop]).forEach((styleProp: any) => {
        element.style[styleProp] = props[prop][styleProp];
      });
      return;
    } else if (prop === 'children') {
      props[prop].forEach((child: any) => {
        element.appendChild(child);
      });
      return;
    } else if (prop === 'textContent') {
      element.textContent = props[prop];
      return;
    } else if (prop === 'className') {
      element.className = props[prop];
      return;
    }
  });
  return element;
}

/**
 * Creates a column for the print version of the recipe page
 * 
 * @param {string} className - The class name for the column
 * @param {string} text - The text to display in the column
 * 
 * @returns HTMLElement
 */
const createColumn = (className: string, text: string) => {
  if (!text || text.trim() === '') return;

  const innerDiv = createElement('div', {
      className: 'blossman_block-grid-column-inner',
      style: { display: 'flex', justifyContent: 'center', alignItems: 'center' }
  });

  innerDiv.appendChild(createElement('h4', { style: { fontSize: '18px', margin: '0' }, textContent: text }));

  return createElement('div', { className, style: { flex: '1' }, children: [innerDiv] });
}

/**
 * Creates a column for the print version of the recipe page
 * 
 * @param {string} className - The class name for the column
 * @param {string} headerText - The header text for the column
 * @param {string} listType - The type of list to create
 * @param {string} listItems - The items to display in the list
 * @param {boolean} isParagraph - Whether or not the list items should be displayed as a paragraph
 * 
 * @returns HTMLElement
 */
const createColumnList = (className: string, headerText: string, listType: string, listItems: string, isParagraph = false) => {
    const gridColumn = createElement('div', { className });

    gridColumn.appendChild(createElement('h4', { textContent: headerText }));

    if (isParagraph) {
        gridColumn.appendChild(createElement('p', { textContent: listItems }));
    } else {
        const list = createElement(listType);
        list.innerHTML = listItems;
        gridColumn.appendChild(list);
    }

    return gridColumn;
}

/**
 * Creates the print version of the recipe page
 * 
 * @returns string
 */
const RecipePage = () => {
    let print: string;
    const postTitle: string = document.querySelector('.blossman_recipe-title')?.textContent || '';
    const postDesc: string = document.querySelector('.blossman_recipe-description')?.textContent || '';
    const postImg: string = document.querySelector('.blossman_recipe-img')?.getAttribute('src') || '';
    const postSrv: string = document.querySelector('.blossman_recipe-serves')?.textContent || '';
    const postPrep: string = document.querySelector('.blossman_recipe-prep')?.textContent || '';
    const postCook: string = document.querySelector('.blossman_recipe-cook')?.textContent || '';

    const postIngrd: string = Array.from(document.querySelectorAll('.blossman_recipe-ingredient-list li')).map(el => `<li>${el.textContent}</li>`).join('');
    const postDirect: string = Array.from(document.querySelectorAll('.blossman_recipe-direction-list li')).map(el => `<li>${el.textContent}</li>`).join('');

    const postOptional: string = document.querySelector('.blossman_recipe-optional')?.textContent || '';

    const postoList: string = Array.from(document.querySelectorAll('.blossman_recipe-optional-list li')).map(el => `<li>${el.textContent}</li>`).join('');
    
    const postLast: string = document.querySelector('.blossman_recipe-last')?.textContent || '';
    const location = window.origin;

    const html = createElement('html');

    const head = document.createElement('head');
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = `${location}/wp-content/themes/Blocks%20Theme/style.css`;
    link.media = 'all';
    head.appendChild(link);
    html.appendChild(head);

    const body = document.createElement('body');
    body.className = 'print-body';

    const img = document.createElement('img');
    img.id = 'recipeLogo';
    img.src = `${location}/wp-content/uploads/2023/11/Blossman-Gas-Logo.svg`;
    img.alt = 'Blossman Gas Logo';
    body.appendChild(img);

    const divGrid = document.createElement('div');
    divGrid.className = 'block-grid-print';

    const h1 = document.createElement('h1');
    h1.className = 'block-recipe-title-print';
    h1.textContent = postTitle;
    divGrid.appendChild(h1);

    const gridRow = document.createElement('div');
    gridRow.className = 'blossman_block-grid-row';

    const gridColumnPrintLeft = document.createElement('div');
    gridColumnPrintLeft.className = 'block-grid-column-print-left';

    const p = document.createElement('p');
    p.setAttribute('itemprop', 'description');
    p.className = 'block-description-print';
    p.textContent = `${postDesc}`;

    gridColumnPrintLeft.appendChild(p);

    const gridColumnPrintRight = document.createElement('div');
    gridColumnPrintRight.className = 'block-grid-column-print-right';

    const imgPost = document.createElement('img');
    imgPost.setAttribute('itemprop', 'image');
    imgPost.className = 'block-recipe-img-print';
    imgPost.src = `${postImg}`;
    imgPost.alt = `${postTitle}`;
    imgPost.title = `${postTitle}`;

    gridColumnPrintRight.appendChild(imgPost);

    gridRow.appendChild(gridColumnPrintLeft);
    gridRow.appendChild(gridColumnPrintRight);

    const gridRowDiv = document.createElement('div');
    gridRowDiv.className = 'blossman_block-grid-row blossman_block-grid-divider';

    const columnsData = [
        { className: 'blossman_recipe-serves', text: postSrv },
        { className: 'blossman_recipe-prep', text: postPrep },
        { className: 'blossman_recipe-cook', text: postCook },
    ];

    const nonEmptyColumnsData = columnsData.filter(column => column.text && column.text.trim() !== '');

    const columnClass = `block-grid-column-print-${nonEmptyColumnsData.length}`;

    nonEmptyColumnsData.forEach(columnData => {
        const column = createColumn(`${columnClass} ${columnData.className}`, columnData.text);
        if (column) gridRowDiv.appendChild(column);
    });

    let gridRowList = document.createElement('div');
    gridRowList.className = 'blossman_block-grid-row';

    gridRowList.appendChild(createColumnList('block-grid-column-print', 'Ingredients', 'ul', postIngrd));
    gridRowList.appendChild(createColumnList('block-grid-column-print', 'Directions', 'ol', postDirect));
    gridRowList.appendChild(createColumnList('block-grid-print', postOptional, 'ul', postoList));
    gridRowList.appendChild(createColumnList('block-grid-print', '', '', postLast, true));

    body.appendChild(divGrid);
    body.appendChild(gridRow);
    body.appendChild(gridRowDiv);
    body.appendChild(gridRowList);
    html.appendChild(body);

    print = html.outerHTML;

    return print;
};

export default RecipePage;