import GetApplianceList from '../../routes/get-appliance-list';

import ApplianceList from './appliance-compare';

/**
 * Compare Appliances
 * 
 * @returns void
 */
const CompareAppliances = () => {
  const compareButton = document.querySelector('.blossman_button-filter') as HTMLElement;
  const clearButton = document.querySelector('.blossman_button-clear') as HTMLElement;
  const appliances = document.querySelectorAll('.blossman_appliance-card-compare') as NodeListOf<HTMLElement>;

  if ( !compareButton ) return;

  const clearCompare = () => {
    const compareAppliances = document.querySelectorAll('.blossman_appliance-card') as NodeListOf<HTMLElement>;
    const applianceCompare = document.querySelector('.blossman_appliance-compare') as HTMLElement;
    clearButton.style.display = 'none';
    applianceCompare.innerHTML = '';

    compareAppliances.forEach( appliance => {
      appliance.style.display = 'flex';

      if (appliance.hasAttribute('data-app')) {
        appliance.removeAttribute('data-app');
      }

      const com = appliance.querySelector('.bg_compare')! as HTMLElement;
      const container = appliance.querySelector('.blossman_appliance-card-compare')! as HTMLElement;
      if (com) {
        container.classList.remove('bg_compare-active');
        com.classList.remove('fa-check');
        com.classList.add('fa-plus');
      }
    });
  };

  appliances.forEach( appliance => {
    appliance.addEventListener('click', (e) => {
      const target = e.target as HTMLElement;
      const child = target.firstElementChild! as HTMLElement;
      const parent = target.parentElement!.parentElement as HTMLElement;

      if (child.classList.contains('fa-plus')) {
        child.classList.remove('fa-plus');
        child.classList.add('fa-check');
        const appID = target.getAttribute('data-appid');
        parent.dataset.app = appID!;
        target.classList.add('bg_compare-active');
      } else {
        child.classList.remove('fa-check');
        child.classList.add('fa-plus');   
        target.classList.remove('bg_compare-active');   
      }
    });
  });

  compareButton.addEventListener('click', async () => {
    const compareAppliances = document.querySelectorAll('.blossman_appliance-card') as NodeListOf<HTMLElement>;
    const activeAppliances = document.querySelectorAll('.bg_compare-active') as NodeListOf<HTMLElement>;

    if (activeAppliances.length < 2) {
      alert('Please select at least two appliances to compare.');
      clearCompare();
      return;
    }

    clearButton.style.display = 'block';

    const appIDs = Array.from(activeAppliances).map( appliance => appliance.getAttribute('data-appid'));
    const appList = await GetApplianceList(appIDs.join(','));

    ApplianceList(appList);

    compareAppliances.forEach( appliance => {
      //if (!appliance.getAttribute('data-app')) {
        appliance.style.display = 'none';
      //}
    });
  });

  clearButton.addEventListener('click', clearCompare);
}

export default CompareAppliances;