import CheckIfMobile from "../../helpers/check-device";
import { getElements, handleMouseOver, handleMouseOut, handleMobileView } from "./history-functions";

/**
 * History page animations
 * 
 * @returns void
 */
const HistoryPageAnimations = () => {
  const hisContainer = document.querySelectorAll('.bg_content-history-inner') as NodeListOf<HTMLElement>;

  if ( !hisContainer ) return;
  const isMobile = CheckIfMobile();
  let n: number;

  hisContainer.forEach((container, i) => {
    const elements = getElements(container);
    
    if (elements.tn) n = elements.tn.offsetWidth / 2 - 40;
    if(elements.historyImageTextTwo) elements.historyImageTextTwo.style.transform = `translate(${n}px, 0px)`;

    if ( !isMobile ) {
      container.addEventListener('mouseover', () => handleMouseOver(elements, n));
      container.addEventListener('mouseout', () => handleMouseOut(elements, n));
    } else {
      container.classList.remove('bg_display');
      container.removeAttribute("style");
      handleMobileView(elements);
    }
  });
}

export default HistoryPageAnimations;