import RecipePage from "./recipe-page";

/**
 * Handles the print functionality for the recipe page
 * 
 * @returns void
 */
const RecipePrint = () => {
  const printButton = document.getElementById('blossan_recipe-print') as HTMLElement;
  if ( !printButton ) return;

  const printFormat = () => {
    const printContents = RecipePage();
    const printWindow = window.open('', '_blank');
    
    if (printWindow) {
      printWindow.document.write(printContents);
      setTimeout(() => {
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
      }, 200);
      printWindow.onafterprint = () => printWindow.close();
    }
  };

  printButton.addEventListener('click', printFormat);
};

export default RecipePrint;