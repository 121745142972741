/**
 * Script for the Tall Image Gallery block.
 */
const TallImage = () => {
    const imgSections = document.querySelectorAll<HTMLElement>('.hover')
    if( imgSections) {
        imgSections.forEach((section) => {
            const imgText = section.querySelector('.bg_link-block-img-text') as HTMLElement;
            const imgHeader = section.querySelector('.bg_link-block-img-header') as HTMLElement;

            section.addEventListener('mouseover', () => {
                const y = imgText.offsetHeight + 50;
                imgText.style.opacity = '1';
                imgText.style.transform = 'translateX(35px)';
                imgHeader.style.transform = `translateY(-${y}px)`;
            });

            section.addEventListener('mouseout', () => {
                imgText.style.opacity = '0';
                imgText.style.transform = 'translateX(0px)';
                imgHeader.style.transform = 'translateY(0px)';
            });
        });
    }
};

export default TallImage;