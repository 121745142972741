import Navbar from './components/Navbar/Navbar';
import CompareAppliances from './components/Appliances/compare';
import SortAppliance from './components/Appliances/sort';
import RecipePrint from './components/Recipe/recipe';
import Carousel from './components/Carousel/carousel';
import ImageGallery from './components/Appliances/image-gallery';
import HistoryPageAnimations from './components/Animations/history-page';
import TallImage from './components/Galleries/tall-image';
import { returnButton } from './components/returnbutton';

export const app = () => {
  Navbar();
  returnButton();

  Carousel();
  CompareAppliances();
  SortAppliance();
  ImageGallery();
  RecipePrint();
  TallImage();

  HistoryPageAnimations();
}