/**
 * Gets the elements for the history section
 * 
 * @param container - the container to get the elements from
 * @returns the elements
 */
const getElements = (container: HTMLElement) => ({
  historyImageTextTwo: container.querySelector('.bg_history-fact-header-overlay-span') as HTMLElement,
  historyImageText: container.querySelector('.bg_history-fact-header-overlay') as HTMLElement,
  historyImageTextBottom: container.querySelector('.bg_history-fact-header-bottom') as HTMLElement,
  historyImagePara: container.querySelector('.bg_history-image-para') as HTMLElement,
  historyImageFooter: container.querySelector('.bg_history-image-footer') as HTMLElement,
  historyFlex: container.querySelector('.bg_history-image-footer-height') as HTMLElement,
  historyOverlay: container.querySelector('.bg_overlay-effect') as HTMLElement,
  tn: container.querySelector('.bg_content-history-span') as HTMLElement
});

/**
 * Handles the mouse out event for the history section
 * 
 * @param elements - the elements to be handled
 * @param n - the number to be handled
 * 
 * @returns void
 */
const handleMouseOver = (elements: any, n: number) => {
  const { historyImageText, historyImageTextTwo, historyImagePara, historyImageFooter, historyImageTextBottom, historyFlex } = elements;
  if (historyImageText) {
    historyImageText.style.transform = 'translate(-50%, 30px)';
    historyImageText.classList.add('bg_history-line');
    historyImageText.style.opacity = '1';
    historyImagePara.style.opacity = '1';
    historyImagePara.style.transform = 'translateY(92px)';
  } else if(historyImageTextTwo) {
    historyImageTextTwo.style.transform = `translate(${n}px, 30px)`;
    historyImageTextTwo.classList.add('bg_history-line');
    historyImageTextTwo.style.opacity = '1';
    historyImagePara.style.opacity = '1';
    historyImagePara.style.transform = 'translateY(92px)';
  } else if (historyImageFooter || historyFlex) {
    (historyImageFooter || historyFlex).style.opacity = '.9';
    historyImageTextBottom.style.opacity = '1';            
  }
};

/**
 * Handles the mouse out event for the history section
 * 
 * @param elements - the elements to be handled
 * @param n - the number to be handled
 * 
 * @returns void
 */
const handleMouseOut = (elements: any, n: number) => {
  const { historyImageText, historyImageTextTwo, historyImagePara, historyImageFooter, historyImageTextBottom, historyFlex } = elements;
  if (historyImageText) {
    historyImageText.style.transform = 'translate(-50%, 0)';
    historyImageText.classList.remove('_bg_history-line');
    historyImageText.style.opacity = '0';
    historyImagePara.style.opacity = '0';
    historyImagePara.style.transform = 'translateY(0px)';
  } else if(historyImageTextTwo) {
    historyImageTextTwo.style.transform = `translate(${n}px, 0px)`;
    historyImageTextTwo.classList.remove('_bg_history-line');
    historyImageTextTwo.style.opacity = '0';
    historyImagePara.style.opacity = '0';
    historyImagePara.style.transform = 'translateY(0px)';
  } else if (historyImageFooter || historyFlex) {
    (historyImageFooter || historyFlex).style.opacity = '0';
    historyImageTextBottom.style.opacity = '0';            
  }
};

/**
 * Handles the mobile view for the history section
 * 
 * @param elements - the elements to be handled
 * @returns void
 */
const handleMobileView = (elements: any) => {
  const { historyOverlay, historyImageText, historyImagePara, historyImageTextTwo, historyImageFooter, historyImageTextBottom, historyFlex } = elements;
  if (historyOverlay) {
    historyOverlay.style.backgroundColor = 'inherit';
  } else if (historyImageText) {
    historyImageText.style.display = 'none';
    historyImagePara.style.display = 'none';                        
  } else if (historyImageTextTwo) {
    historyImageTextTwo.style.display = 'none';
  } else if (historyImageFooter) {
    historyImageFooter.style.opacity = '.9';
    historyImageTextBottom.style.opacity = '1';
  } else if (historyFlex) {
    historyFlex.style.opacity = '.9';
    historyImageTextBottom.style.opacity = '1';
    historyFlex.style.height = '72px';
  }
};

export { getElements, handleMouseOver, handleMouseOut, handleMobileView };