/**
 * external imports
 */
import Swiper from 'swiper';
import { Navigation, Thumbs } from 'swiper/modules';

/**
 * internal imports
 */

const ImageGallery = () => {
    const gallery = document.querySelector('.blossman_appliance-image-container') as HTMLElement;
    if (!gallery) return;

    const thumbNav = new Swiper(".bg_thumbnail-image-container", {
        spaceBetween: 10,
        slidesPerView: 'auto',
        freeMode: true,
        watchSlidesProgress: true,
        breakpoints: {
            0: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            }
        }
    });

    const mainGallery = new Swiper(".bg_main-image-container", {
        modules: [Navigation, Thumbs],
        spaceBetween: 10,
        navigation: {
            nextEl: ".bg_button-next",
            prevEl: ".bg_button-prev",
        },
        thumbs: {
            swiper: thumbNav,
        }
    });
};

export default ImageGallery;