export const returnButton = () => {
  let html = document.querySelector('html') as HTMLElement;
  let button = document.querySelector('.button-top') as HTMLElement;
  let scrollTimer: any;

  if (!button) return;

  button.addEventListener('click', () => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  });

  const doButton = () => {
    clearTimeout(scrollTimer);

    scrollTimer = setTimeout(() => {
      if ( html.scrollTop > 150) {
        button.classList.add('button-top-visible');
      } else {
        button.classList.remove('button-top-visible');
      }
    }, 250);
  }

  if ( html.scrollTop > 150) {
    doButton();
  }

  window.addEventListener('scroll', () => doButton() );
}