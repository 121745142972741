/**
 * Check if the device is mobile
 * 
 * @returns boolean - true if mobile, false if not
 */
const CheckIfMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export default CheckIfMobile;
