import { Appliance } from '../../interfaces';

/**
 * Recieves an array of appliance objects and creates a comparison card list
 * 
 * @param applianceObject
 * 
 * @returns void
 */
const ApplianceList = (applianceObject: any) => {
  const applianceCompare = document.querySelector('.blossman_appliance-compare') as HTMLElement;
  //console.log(applianceObject);

  const appHeading = document.createElement('h2');
  appHeading.textContent = 'Appliances Comparison Results';
  applianceCompare!.appendChild(appHeading);

  const appInner = document.createElement('div');
  appInner.classList.add('blossman_appliance-compare-inner');

  applianceObject.forEach( (appliance: Appliance) => {
    const applianceCard = document.createElement('article');
    applianceCard.classList.add('bg_compare-card');
    applianceCard.setAttribute('data-app', appliance.id.toString());

    const features = appliance.Features.map( feature => {
      return `<li>${feature}</li>`;
    }).join('');

    applianceCard.innerHTML = `
        <div class="bg_compare-title">
          <h4>${appliance.Name}</h4>
        </div>
        <div class="bg_compare-img">
          <figure>
            <img src="${appliance.PostImage}" alt="">
          </figure>
        </div>
        <div class="bg_compare-meta">
          <span>Model: ${appliance.Model}</span>
          <span>Features:</span>
          <ul class="bg_compare-features">
            ${features}
          </ul>
        </div>
        <div class="bg_compare-description">
          <p>${appliance.Description}</p>
        </div>
    `;
    appInner!.appendChild(applianceCard);
  });
  applianceCompare!.appendChild(appInner);
}

export default ApplianceList;