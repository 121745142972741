export const BLOSSMAN_NAVBAR = document.querySelector('.blossman_navbar') as HTMLElement;

export const MapFormElements = { 
  mapElement: document.querySelector('#blossman_map') as HTMLElement,
  addressButton: document.querySelector('#searchLoc') as HTMLFormElement,
  geoButton: document.querySelector('#blossman_find-geo-location') as HTMLElement,
  zipCodeInputBox: document.querySelector('#zipCodeInput') as HTMLFormElement,
  locationDistanceSelect: document.querySelector('#loc_dist') as HTMLSelectElement,
  zipCodeLookUpError: document.querySelector('.blossman_location-error') as HTMLElement,
  displayResults: document.querySelector('#blossman_location-list') as HTMLElement,
  resultsHeader: document.querySelector('.blossman_closest-header') as HTMLElement,
  resultsBody: document.querySelector('.blossman_closest-body') as HTMLElement,
  resultsFooter: document.querySelector('.blossman_closest-footer') as HTMLElement,
  marker: `/wp-content/uploads/2021/02/Customer-Care_32x32.png`,
  markers: <object[]>[]
}