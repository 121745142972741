import { BLOSSMAN_NAVBAR } from "../../types/types";

/**
 * Function controls the height of the main navigation bar for the site.
 * The added css class is for desktop only.  This function is not called
 * on mobile.
 * 
 * @returns void
 */

const NavbarScroll = () => {
  if (!BLOSSMAN_NAVBAR) return;

  window.addEventListener('scroll', () => {
    if( window.scrollY > 175) {
      BLOSSMAN_NAVBAR.classList.add('blossman_solid-navbar');
    } else {
      BLOSSMAN_NAVBAR.classList.remove('blossman_solid-navbar');
    }
  });

  if(window.scrollY > 175) {
    BLOSSMAN_NAVBAR.classList.add('blossman_solid-navbar');
  } else {
    BLOSSMAN_NAVBAR.classList.remove('blossman_solid-navbar');
  }
}

export default NavbarScroll;