import Flickity from "flickity";

/**
 * Hnadles the carousel functionality
 */
const Carousel = () => {
  const carouselEle = document.querySelector(".blossman_carousel") as HTMLElement;
  if (!carouselEle) return;

  const carousel = new Flickity(carouselEle, {
    contain: true,
    wrapAround: true,
    prevNextButtons: true,
    pageDots: false,
    autoPlay: 5000,
  });
}

export default Carousel;