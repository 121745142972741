import NavbarScroll from "./NavbarScroll";
import NavbarToggle from "./NavbarToggle";
import NavbarSubMenu from "./NavbarSubMenu";

/**
 * Main function to control the Main Navigation for the site.
 * 
 * @returns void
 */
const Navbar = () => {
  NavbarToggle();
  NavbarSubMenu();

  if ( window.screen.width > 1025 ) {
    NavbarScroll();
  }
}

export default Navbar;
