import axios from 'axios';

/**
 * Get a list of appliances
 * 
 * 
 */
const GetApplianceList = async (applianceList: string) => {
  try {
    const response = await axios.get('https://blossmangasnew.kinsta.cloud/wp-json/blossman/v2/list-appliance', {
      params: {
        appID: applianceList,
      },    
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching appliances', error);
  }
};

export default GetApplianceList;