/**
 * Sorts the appliance by price.
 * 
 * @returns void
 */
const SortAppliance = () => {
  const sortButton = document.querySelector('.blossman_button-sort') as HTMLElement;
  const appliances = document.querySelectorAll('.blossman_appliance-card') as NodeListOf<HTMLElement>;

  if ( !sortButton ) return;

  const sortAppliances = (appliances: NodeListOf<HTMLElement>, isAscending: boolean) => {
    const sortedAppliances = Array.from(appliances).sort((a, b) => {
      const priceA = parseFloat(a.getAttribute('data-price')!);
      const priceB = parseFloat(b.getAttribute('data-price')!);
      return isAscending ? priceA - priceB : priceB - priceA;
    });

    const applianceCompare = document.querySelector('#blossman_appliance-list') as HTMLElement;
    applianceCompare.innerHTML = '';

    sortedAppliances.forEach(appliance => {
      applianceCompare.appendChild(appliance);
    });
  }

  sortButton.addEventListener('click', () => {
    const isAscending = sortButton.dataset.sort === 'high';
    sortButton.dataset.sort = isAscending ? 'low' : 'high';
    sortButton.innerText = isAscending ? 'Sort High to Low' : 'Sort Low to High';
    sortAppliances(appliances, isAscending);
  });
}

export default SortAppliance;