/**
 * Functionality for the main navigation toggle button for mobile devices.
 * 
 * 
 */
const NavbarToggle = () => {
  const BLOSSMAN_NAVBAR_TOGGLE = document.querySelector('[data-target]') as HTMLElement;

  if (!BLOSSMAN_NAVBAR_TOGGLE) return;

  BLOSSMAN_NAVBAR_TOGGLE.addEventListener('click', () => {
    BLOSSMAN_NAVBAR_TOGGLE.classList.toggle('collapsed');
    const BLOSSMAN_NAVBAR_COLLAPSE = document.querySelector(BLOSSMAN_NAVBAR_TOGGLE.dataset.target!) as HTMLElement;
    BLOSSMAN_NAVBAR_COLLAPSE.classList.toggle('show');
  });
}

export default NavbarToggle;